<template>
  <div
    class="hover-dropdown-fade w-auto px-0 ml-6 position-relative hp-cursor-pointer d-flex align-items-center"
  >
    <dev-enviroment v-if="isDevEnviroment"></dev-enviroment>
    <span v-if="currentUser" class="mx-2">
      {{ currentUser.first_name }}{{ " " }} {{ currentUser.last_name }}
    </span>
    <div
      v-if="currentUser"
      class="hp-cursor-pointer border hp-border-color-dark-80"
      style="border-radius: 15px"
    >
      <div class="rounded-lg overflow-hidden m-4 d-flex">
        <b-avatar
          variant="none"
          :src="
            currentUser.image
              ? currentUser.image
              : require('@/assets/img/app/user/user-icon.png')
          "
          size="32px"
          class="rounded-0 hp-bg-info-4"
        />
      </div>
    </div>

    <div
      class="hp-header-profile-menu dropdown-fade position-absolute pt-18"
      style="top: 100%; width: 260px"
    >
      <div class="rounded hp-bg-black-0 hp-bg-dark-100 py-24 px-18">
        <span
          class="d-block h5 font-weight-medium hp-text-color-black-100 hp-text-color-dark-0 mb-16"
        >
          Configuración de Perfil
        </span>

        <b-link to="/pages/profile/personel-information" class="text-bg-80">
          Ver mi perfil
        </b-link>

        <b-row>
          <b-col cols="12" class="mt-4">
            <b-link @click="logoutUser" class="text-bg-80">
              Cerrar sesión
            </b-link>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar, BLink } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DevEnviroment from "../../../view/components/common/DevEnviroment.vue";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BLink,
    DevEnviroment,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isDevEnviroment() {
      return process.env.VUE_APP_ENVIROMENT_VARIABLE === "development";
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
    logoutUser() {
      this.signOut().then(() => {
        this.$router.push("/auth/login");
      });
    },
  },
};
</script>
