<template>
  <div class="hp-header-logo d-flex align-items-center">
    <b-link to="/" class="position-relative d-flex">
      <img
        v-if="
          this.$store.state.themeConfig.direction == 'rtl' &&
          this.$store.state.themeConfig.theme == 'light'
        "
        class="hp-logo"
        v-bind:src="require('@/assets/img/app/logo/logo_fluxsolar.png')"
        alt="logo"
      />
      <img
        v-if="
          this.$store.state.themeConfig.direction == 'rtl' &&
          this.$store.state.themeConfig.theme == 'dark'
        "
        class="hp-logo"
        v-bind:src="require('@/assets/img/app/logo/logo_fluxsolar.png')"
        alt="logo"
      />
      <img
        v-else-if="
          this.$store.state.themeConfig.direction == 'ltr' &&
          this.$store.state.themeConfig.theme == 'light'
        "
        class="hp-logo"
        v-bind:src="require('@/assets/img/app/logo/logo_fluxsolar.png')"
        alt="logo"
      />
      <img
        v-else-if="
          this.$store.state.themeConfig.direction == 'ltr' &&
          this.$store.state.themeConfig.theme == 'dark'
        "
        class="hp-logo"
        v-bind:src="require('@/assets/img/app/logo/logo_fluxsolar.png')"
        alt="logo"
      />
    </b-link>

    <a
      href="https://hypeople-studio.gitbook.io/yoda/change-log"
      target="_blank"
      class="hp-caption font-weight-normal hp-text-color-success-1"
    >
      v.{{ this.$store.state.themeConfig.version }}
    </a>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink
  }
}
</script>
