var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hp-header-logo d-flex align-items-center"},[_c('b-link',{staticClass:"position-relative d-flex",attrs:{"to":"/"}},[(
        this.$store.state.themeConfig.direction == 'rtl' &&
        this.$store.state.themeConfig.theme == 'light'
      )?_c('img',{staticClass:"hp-logo",attrs:{"src":require('@/assets/img/app/logo/logo_fluxsolar.png'),"alt":"logo"}}):_vm._e(),(
        this.$store.state.themeConfig.direction == 'rtl' &&
        this.$store.state.themeConfig.theme == 'dark'
      )?_c('img',{staticClass:"hp-logo",attrs:{"src":require('@/assets/img/app/logo/logo_fluxsolar.png'),"alt":"logo"}}):(
        this.$store.state.themeConfig.direction == 'ltr' &&
        this.$store.state.themeConfig.theme == 'light'
      )?_c('img',{staticClass:"hp-logo",attrs:{"src":require('@/assets/img/app/logo/logo_fluxsolar.png'),"alt":"logo"}}):(
        this.$store.state.themeConfig.direction == 'ltr' &&
        this.$store.state.themeConfig.theme == 'dark'
      )?_c('img',{staticClass:"hp-logo",attrs:{"src":require('@/assets/img/app/logo/logo_fluxsolar.png'),"alt":"logo"}}):_vm._e()]),_c('a',{staticClass:"hp-caption font-weight-normal hp-text-color-success-1",attrs:{"href":"https://hypeople-studio.gitbook.io/yoda/change-log","target":"_blank"}},[_vm._v(" v."+_vm._s(this.$store.state.themeConfig.version)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }