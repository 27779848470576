<template>
  <div class="d-flex align-items-center mx-3">
    <b-alert show class="bg-danger pt-1 pb-0">
      <h5 class="mt-2 text-white">Entorno de Desarrollo</h5>
    </b-alert>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
